<template>
  <svg
    id="exit_icon"
    data-name="exit icon"
    xmlns="http://www.w3.org/2000/svg"
    width="19.759"
    height="19.759"
    viewBox="0 0 19.759 19.759">
    <rect
      id="Rectangle_87"
      data-name="Rectangle 87"
      width="4.931"
      height="23.012"
      rx="2.466"
      transform="translate(16.272) rotate(45)"
      fill="#9ebac9" />
    <rect
      id="Rectangle_132"
      data-name="Rectangle 132"
      width="4.931"
      height="23.012"
      rx="2.466"
      transform="translate(19.759 16.272) rotate(135)"
      fill="#9ebac9" />
  </svg>
</template>
