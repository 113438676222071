import { defineStore } from "pinia";
import { MODAL_DATA } from "@/utils/constants";

export const useModalStore = defineStore("modal", {
	state: () => ({
		//Modal dialog
		isModalOpen: false,
		type: null,
		data: null,

		//Slide over
		isSlideOverVisible: false,
		panelTitle: "",
		panelContentType: "",
		panelData: {},
	}),
	actions: {
		displayModal(modalType, userType) {
			const client = userType ? "clients" : "staff";
			const data = MODAL_DATA[modalType][client];

			this.isModalOpen = !this.isModalOpen ? true : false;
			this.type = this.isModalOpen ? modalType : null;
			this.data = this.isModalOpen ? data : null;
		},
		toggleSlideOver(value) {
			this.isSlideOverVisible = value;
		},
		setPanelContent(
			title = "Details",
			type,
			data = "Nothing to view yet"
		) {
			this.panelTitle = title;
			this.panelContentType = type;
			this.panelData = data;
		},
	},
});
