import { useModalStore } from "@/stores/modal";

export const useModal = () => {
	const store = useModalStore();

	const {
		isSlideOverVisible,
		panelContentType,
		panelData,
	} = toRefs(store);

	return {
		isSlideOverVisible,
		panelContentType,
		panelData,
		displayModel: store.displayModal,
		toggleSlideOver: store.toggleSlideOver,
		setPanelContent: store.setPanelContent,
	};
};
